import React from 'react';
import './About.css';
import AboutMeCard from './components/AboutMeCard.js';

//https://particles.js.org/samples/presets/index.html

class About extends React.Component {
  render() {
    let aboutMeCard = {title: 'Hi there!', shortDescription: "I'm Alexandru, a developer with over six years of work experience who likes to explore, create fun projects, and share my knowledge. Click on the links below to discover more.", img: '/profile_img.jpg', contactList: [{name:'github', url: 'https://github.com/nighthawk017'},{name:'linkedin', url: 'www.linkedin.com/in/alexandru-madalin-ghenea-b7939167'},{name:'medium', url: 'https://medium.com/@nighthawk017'},{name:'twitter', url: 'https://twitter.com/madalinghenea'}]};
    return (
      <div className="Home">
        <AboutMeCard cardInfo={aboutMeCard}>
        </AboutMeCard>
      </div>
    );
  }
}

export default About;
