import React from 'react';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';

export const SidebarData = [
  {
    title: 'Home',
    path: '/',
    icon: <AiIcons.AiFillHome />,
    cName: 'nav-text'
  },
  {
    title: 'Games',
    path: '/games',
    icon: <IoIcons.IoIosPlayCircle />,
    cName: 'nav-text'
  },
  {
    title: 'Math Sounds',
    path: '/math-sounds',
    icon: <IoIcons.IoMdVolumeHigh />,
    cName: 'nav-text'
  },
  {
    title: 'Talks & Course',
    path: '/course',
    icon: <IoIcons.IoIosPaper />,
    cName: 'nav-text'
  }
];