import React from 'react';
import './Home.css';
import './Projects.css';
import Card from './components/Card';

class MathSounds extends React.Component {

  
  render() {

    let cardMathSounds = {title: 'Math Sounds',  img: '/math_sounds.jpg', shortDescription: 'Youtube channel that contains music generated using math. Discover the sound of Pi, e, and Square root of 2.', url: 'https://www.youtube.com/channel/UC_3XMSZ9vj_RB0RRqfCUybw/featured'};
    let cardSoundOfPi = {title: 'The sound of Pi in C major scale', videoUrl: 'https://www.youtube.com/embed/zBDnKG9HLyQ', shortDescription: 'Video playing the digits of Pi constant (in base 10) in the C major scale. The notes are played every 0.318 seconds (every 1/Pi seconds).'};
    let cardSoundOfE = {title: 'The sound of e (in base 8) in C major scale',  videoUrl: 'https://www.youtube.com/embed/fojD476qiKs', shortDescription: 'Video playing the digits of e (in base 8) in C major scale. The notes are played every 0.367 seconds (every 1/e seconds).'};
    let cardSoundOfSqrt2 = {title: 'The sound of the square root of 2',  videoUrl: 'https://www.youtube.com/embed/ejrocdDjZgM', shortDescription: 'Video playing the digits of the square root of 2 (in base 10) in the C major scale. The notes are played every 0.2 seconds.'};
    let cardTitle = { title: 'Math Sounds' }

    return ( 
      <div className="Home">
        <div className="Content">
          <Card cardInfo={cardTitle} ></Card>
          <Card cardInfo={cardMathSounds} ></Card>
          <Card cardInfo={cardSoundOfPi} ></Card>          
          <Card cardInfo={cardSoundOfE} ></Card>  
          <Card cardInfo={cardSoundOfSqrt2} ></Card>         
        </div>
      </div>
    );
  }

}

export default MathSounds;
