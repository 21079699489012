import React from 'react';
import './Bootstrap.css';
import './Card.js';
import './AboutMeCard.css';
import { Link } from "react-router-dom";
import { SocialIcon } from 'react-social-icons'

class AboutMeCard extends React.Component {
  render() {
    const cardInfo = this.props.cardInfo;
    console.log(cardInfo.contactList.length);
    return (
      
      <div className="Card">
        <div>
          <h2 className="title">{cardInfo.title}</h2>
          {cardInfo.img &&
            <img src={cardInfo.img} className="card-image section" alt={cardInfo.alt} />
          }
          {cardInfo.shortDescription &&
            <div className="section" >{cardInfo.shortDescription}
            </div>
          }
          <div className='horizontal-container'>
            
            {cardInfo.contactList && cardInfo.contactList.map((element) =>

              <div className="contact-list-element" key={element.name} >
                <SocialIcon network={element.name}  url={element.url} className='border-test'/>
              </div>
            )}
          </div>
          <div className="horizontal-container">
            <div className='link-item'>
              <Link to='/projects' className='link'>
                My projects
              </Link>
            </div>
            {/* <div className='link-item'>
              <Link to='/about' className='link' >
                About me
              </Link>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default AboutMeCard;
