import React from 'react';
import { Link } from 'react-router-dom';
import './Bootstrap.css';
import './Card.css';

class Card extends React.Component {
  render() {
    const cardInfo = this.props.cardInfo;
    return (
      <div className="Card">
        <div>
          <h2 class="title">{cardInfo.title}</h2>
          {cardInfo.img &&
            <img src={cardInfo.img} className="card-image section" alt={cardInfo.alt} />
          }
          {cardInfo.videoUrl &&
            <div className='embed-youtube'>
              <iframe width="560" height="315" src={cardInfo.videoUrl} class="section" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          }
          {cardInfo.shortDescription &&
            <div class="section" >
              {cardInfo.shortDescription}
            </div>
          }
          {cardInfo.url &&
            <div class="link-section">
              <a href={cardInfo.url} >{cardInfo.title}</a>
            </div>
          }
          {cardInfo.storeLink &&
            <a href={cardInfo.storeLink}><img alt='Get it on Google Play' class='store-button' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
          }
          {cardInfo.link && 
            <Link to={cardInfo.link}>
              {cardInfo.linkText}
            </Link>
          }
        </div>
      </div>
    );
  }
}

export default Card;
