import React from 'react';
import './Home.css';
import './Projects.css';
import Card from './components/Card';


class Games extends React.Component {

  
  render() {
    let cardGames = {title: 'Games'};
    let cardInfoBubblyFun = {title: 'Bubbly Fun',  img: '/bubbly.png', storeLink: 'https://play.google.com/store/apps/details?id=com.SoultechSolutions.BubblyFun&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1', shortDescription: 'Think fast, react faster!\nBubbly Fun is a small, fun, and addictive game in which you have to pop as many bubbles as you can before they overwhelm you.\n\nPop different bubbles to get an ever-higher score, from the Freeze Bubble which freezes all other bubbles, to the explosive Splash Bubble and the illusive Teleport and Illusion Bubble.'};
    let cardLingWiz = {title: 'LingWiz',  img: '/lingwiz.png', storeLink: 'https://play.google.com/store/apps/details?id=com.SoultechSolutions.lingwiz&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1', shortDescription: 'Become a linguistic wiz and discover fun and interesting idioms from different countries in an engaging trivia with LingWiz!\n\nPlay Lingwiz and guess the correct meaning of idioms in order to get coins, which will in turn unlock even more idioms from other countries.\n\nDonwload Lingwiz to quiz yourself with 10 collections of phrases from countries around the world, with more collections yet to come.'};
    
    return (  
      <div className="Home">
        <div className="Content">
          <Card cardInfo={cardGames}> </Card>
          <Card cardInfo={cardInfoBubblyFun} ></Card>
          <Card cardInfo={cardLingWiz} ></Card>          
        </div>
      </div>
    );
  }
}

export default Games;
