import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from './Home';
import './App.css';
import Projects from './Projects';
import About from './About';
import Games from './Games';
import MathSounds from './MathSounds';
import Courses from './Courses';
import { HeaderMenu } from './components/HeaderMenu';
import ParticleBackground from './components/ParticleBackground';
import Header from './components/Header';
import {Link}  from "react-router-dom";

const App = () => {
    return (
      <div>
        {/* <ParticleBackground></ParticleBackground> */}
        <HeaderMenu></HeaderMenu>

        

        <Switch>
          <Route exact={true} path="/" component={Home} />
          <Route exact={true} path="/projects" component={Projects} />
          <Route exact={true} path="/games" component={Games} />
          <Route exact={true} path="/math-sounds" component={MathSounds} />
          <Route exact={true} path="/course" component={Courses} />
          <Route exact={true} path="/about" component={About} />
        </Switch>
      </div>
    );
  }

export default App;
