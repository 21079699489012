import React from 'react';
import './Home.css';
import './Projects.css';
import Card from './components/Card';

class Courses extends React.Component {


  render() {


    let cardCyberSecIntro = { title: '2022 Beginner\'s guide to Cyber Security', alt: 'Course image', img: '/course.jpg', shortDescription: `A brief, beginner friendly introduction into Cybersecurity. Some of the main concepts teached in this course are: \n\n - What is Cybersecurity \n - What are Threats & Vulnerabilities\n - Malware: Viruses, Worms, Trojans, Bots, Adware, Spyware, Rootkits, Ransomware - What is Phishing\n - What is a brute force and denial of service attack\n - How to protect your data and devices`, url: 'https://www.udemy.com/course/2021-beginners-guide-to-cyber-security/' };
    let cardDevoxx2023Talk = { title: 'Devoxx 2023: The Fast and the Sustainable: Unleash the Power of Sustainable IT & High Performance Green Code', videoUrl: 'https://www.youtube.com/embed/CbYZQl27ko8?si=hlw3Wt-pJelWoZwy', shortDescription: `A 50 minutes introduction of sustainability in IT by Alexandru-Madalin Ghenea and Marc Cortada Bertomeu \n\nSynopsys:\n\nDo you want to improve the performance of your code while reducing its carbon footprint? Do you want to design your application considering emissions?\n Join us on a hands-on ride to learn some tips & tricks that will improve your architecture and code toward more sustainable applications. In this talk, we'll discover the importance of carbon awareness and green IT. Brace yourself as we unveil a wide-variety of carbon-aware solutions, ranging from high-level strategies to practical to hands-on examples of writing high-performant green code.
Whether you're a performance enthusiast or a green advocate, this talk will help you develop the skills to make IT greener and write code that's both performant and sustainable.`};

    let cardDevoxxTalk = { title: 'Devoxx 2022: NewSQL, the holy grail of databases?', videoUrl: 'https://www.youtube.com/embed/czbExIRmyKo', shortDescription: `A 15 minutes introduction of NewSQL by Alexandru-Madalin Ghenea and Marc Cortada Bertomeu \n\nSynopsys:\n\nOnce upon a time, we only had two types of databases: SQL and NoSQL. Both had advantages and disadvantages, but this allowed us to choose the database that fit our application best. Then one day a challenger appeared: NewSQL. It promises to have the scalability of NoSQL databases as well as the transactional & consistency capabilities of an SQL database. Come with us on a journey to discover whether or not NewSQL is indeed “the chosen one”.`};
    let riskmindsTalk = { title: 'RiskMinds Insurance 2021: Banks vs threats during a pandemic', alt: 'RiskMinds Insurance talk image', img: '/banksVsThreats.jpg', shortDescription: `A talk by Alexandru-Madalin Ghenea and Aurel George Proroocu about the threat trends in banking.` };
    let cardTitle = { title: 'Talks & Course' }

    return (

      <div className="Home">

        {/* <Header></Header>
        <Menu></Menu> */}
        <div className="Content">
          {/* <ParticleBackground></ParticleBackground> */}
          <Card cardInfo={cardTitle} ></Card>
          <Card cardInfo={cardDevoxx2023Talk} ></Card>
          <Card cardInfo={cardDevoxxTalk} ></Card>
          <Card cardInfo={cardCyberSecIntro} ></Card>
          <Card cardInfo={riskmindsTalk} ></Card>
          
        </div>
      </div>
    );
  }
}

export default Courses;
