import React from 'react';
import './Home.css';
import './Projects.css';
import Card from './components/Card';

class Projects extends React.Component {

  render() {
    let cardMathSounds = {title: 'Math Sounds',  img: '/math_sounds.jpg', shortDescription: 'Youtube channel that contains music generated using math. Discover the sound of Pi, e, and Square root of 2.', linkText: 'Click here to find more',  link: '/math-sounds'};
    let cardCyberSecIntro = { title: '2022 Beginner\'s guide to Cyber Security', alt: 'Course image', img: '/course.jpg', shortDescription: `A brief, beginner friendly introduction into Cybersecurity. Some of the main concepts teached in this course are: \n\n - What is Cybersecurity \n - What are Threats & Vulnerabilities\n - Malware: Viruses, Worms, Trojans, Bots, Adware, Spyware, Rootkits, Ransomware - What is Phishing\n - What is a brute force and denial of service attack\n - How to protect your data and devices.`, linkText: 'Click here to find more',  link: '/course' };
    let cardInfoBubblyFun = {title: 'Games',  img: '/bubbly.png', shortDescription: 'Discover small, fun games for Android such as Bubbly Fun and LingWiz. Play Bubbly Fun, which is a small, fun, and addictive game in which you have to pop as many bubbles as you can before they overwhelm you. Alternatively, become a linguistic wiz and discover fun and interesting idioms from different countries in an engaging trivia with LingWiz!', linkText: 'Click here to find more',  link: '/games'};
    return (     
      <div className="Home">
        <div className="Content">
          <Card cardInfo={cardMathSounds} ></Card>
          <Card cardInfo={cardInfoBubblyFun} ></Card>
          <Card cardInfo={cardCyberSecIntro} ></Card>
          
        </div>
      </div>
    );
  }
}

export default Projects;
